import React from 'react';
import logo from './logo.svg';
import styles from './App.module.scss';
import {BrowserRouter, Link, NavLink, Route, Routes} from "react-router-dom";
import News from "./pages/news";
import Imprint from "./pages/imprint";
import Gdpr from './pages/gdpr';
import Home from "./pages/home";
import Contact from "./pages/contact";

function App() {
    return (
        <div className={styles.app}>
            <BrowserRouter basename="/">
                <header className={styles.header}>
                    <div className={styles.image}>
                        <NavLink
                            to={"/"}
                        >
                            <img src={logo} alt="vision2x"/>
                        </NavLink>
                    </div>
                    <div className={styles.nav}>
                        <NavLink
                            to={"/"}
                            className={({isActive}) => (isActive ? styles.active : '')}
                        >
                            About Vision2X
                        </NavLink>
                        {/*<NavLink to={"/news"}
                                 className={({isActive}) => (isActive ? styles.active : '')}
                        >
                            News
                        </NavLink>*/}
                        <NavLink to={"/contact"}
                                 className={({isActive}) => (isActive ? styles.active : '')}
                        >
                            {/*Team & */}Contact
                        </NavLink>
                    </div>
                </header>
                <div className={styles.content}>
                    <div className={styles.container}>
                        <div className={styles.layout}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>*
                                <Route path="/news" element={<News/>}/>
                                <Route path="/imprint" element={<Imprint/>}/>
                                <Route path="/gdpr" element={<Gdpr/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
                <footer className={styles.footer}>

                    <div className={styles.copy}>
                        &copy; {new Date(Date.now()).getFullYear()}
                    </div>
                    <div className={styles.space}/>
                    <div className={styles.imprint}>
                        <NavLink to={"/imprint"}
                                 className={({isActive}) => (isActive ? styles.active : '')}
                        >
                            Imprint
                        </NavLink>
                    </div>
                </footer>
            </BrowserRouter>
        </div>
    );
}

export default App;
