import React from "react";
import styles from './home.module.scss'

interface IHomeProps {

}

const Home: React.FC<IHomeProps> = ({}) => {
    return <>
        <div className={styles.v2x}>
            <h1>Vision2X</h1>
            <h3>Vision2X is a Web3 platform bridging the gap between Big Data and Reporting.</h3>
            <div className={styles.layout}>
                {/*<div className={styles.l1}>
                    <img src="/v2x_bridge_1.jpg" alt="bridge"/>
                </div>*/}
                <div className={styles.l2}>
                    <p>We bring new possibilities into Business Intelligence, creating 3D visualizations to provide
                        companies with accurate, easy-to-understand insights.</p>
                    <p>Mining data from various enterprise systems, our platform generates digital models and uses them
                        for interactive and efficient visualizations.</p>

                </div>
            </div>

        </div>
        <div className={styles.mission}>
            <p>
                Our mission is to overcome limitations in business analytics by using the possibilities of Web3.
            </p>
        </div>
        <div className={styles.approach}>
            <h1>Our Approach</h1>
            <h3>We assist our customers with use cases search and specification and ensure the complete system
                development lifecycle</h3>
            <div className={styles.layout}>
                <div className={styles.l1}>
                    <img src="/v2x_timeline.jpg" alt="approach"/>
                </div>
                <div className={styles.l2}>
                    <ul>
                        <li><span>01</span>In a tight collaboration with company departments, we find areas that benefit
                            from Web3.
                        </li>
                        <li><span>02</span>Gathering and Analysing the data from existing or new systems.
                        </li>
                        <li><span>03</span>Agreement on its most suitable application and define the scope of the full
                            project.
                        </li>
                        <li><span>04</span>Integration, development and testing of the solution</li>
                        <li><span>05</span>We support the customer with maintenance and future enhancements.</li>
                    </ul>
                </div>
            </div>


            {/*<img src="/v2x_mix3features.jpg" alt="features"/>
            <p>Using Cloud and Data Lake technologies as a foundation, we integrate AR as a tool into your daily
                business.</p>
            <p> Our mission is to overcome limitations in Business Process Operations and extend the
                capabilities/efficiency
                of BI activities.</p>*/}
        </div>
        <div className={styles.platform}>
            <h1>Our Platform</h1>
            <div className={styles.layout}>
                <div className={styles.l1}>
                    <img src="/v2x_arch.svg" alt="architecture"/>
                </div>
                <div className={styles.l2}>
                    <ul>
                        <li><strong>Data Lake</strong> continuously loads and processes data from multiple Data Sources
                            to make it accessible for other system modules.
                        </li>
                        <li><strong>Core Rendering Engine</strong> uses procedural modeling to generate a virtual
                            representation, which is streamed to the target.
                        </li>
                        <li><strong>Visualization Targets</strong> are interactive applications for presentation of
                            virtual scenes received from the Vision2X Platform.
                        </li>
                        <li><strong>Realtime Streaming Engine</strong> enables realtime connectivity between all
                            components.
                        </li>
                        <li>The <strong>Web Frontend</strong> provides the user with data and model upload
                            functionality and is used for the complete system administration and monitoring.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
};


export default Home;