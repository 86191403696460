import React from "react";

interface IGdprProps {

}

const Gdpr: React.FC<IGdprProps> = ({}) => {
    return <p>gdpr</p>
};


export default Gdpr;