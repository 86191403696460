import React from "react";

interface INewsProps {

}

const News: React.FC<INewsProps> = ({}) => {
    return <p>news</p>
};


export default News;