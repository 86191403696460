import React from "react";
import {Link} from "react-router-dom";

import styles from "./contact.module.scss"

interface IContactProps {

}

const Contact: React.FC<IContactProps> = ({}) => {
    return <>
        <div className={styles.contact}>

            <h2>Contact</h2>
            <p>Do you want to collaborate on a Web3 project or have any questions?</p>

            <p>Contact us at<Link to="mailto:sales@2x.vision"> sales@2x.vision</Link></p>

        </div>
        {/*<div className={styles.core}>
            <h2>Core Team</h2>
            <div className={styles.us}>
                <div className={styles.cl}>
                    <h3>Olga Klyshevich</h3>
                    <ul>
                        <li>Data Architectures</li>
                        <li>Databases & Data Warehouses</li>
                        <li>Business Process Management</li>
                        <li>Business Intelligence</li>
                        <li>Reporting</li>
                        <li>Requirements Analysis</li>
                        <li>Analytics</li>
                        <li>IOT</li>
                    </ul>
                </div>
                <div className={styles.cm}>
                    <img src="/team.jpg" alt="team"/>
                </div>
                <div className={styles.cr}>
                    <h3>David Lechner</h3>
                    <ul>
                        <li>Cloud Infrastructures</li>
                        <li>Video Streaming</li>
                        <li>3D Development</li>
                        <li>Embedded Systems</li>
                        <li>AR/VR Technologies</li>
                        <li>Business Development</li>
                        <li>High Performance Computing</li>
                        <li>Product & Software Design</li>
                    </ul>
                </div>
            </div>
            <div className={styles.cmd}>
                <img src="/team.jpg" alt="team"/>
            </div>
        </div>*/}
        <div className={styles.desc}>
            As a team of professionals in data analysis and 3D visualization we want to help your business grow
            by introducing modern technologies into your processes, making the daily business more interactive, accurate
            and data-driven.
        </div>
    </>
}


export default Contact;