import React from "react";

import styles from './imprint.module.scss'

interface IImprintProps {

}

const Imprint: React.FC<IImprintProps> = ({}) => {
    return <div className={styles.imprint}>
        <h2>SuperBee Keeper GmbH</h2>
        <h3>Address</h3>
        <p>Almen 14, A-2853 Bad Schönau, Austria<br/>
            UID: ATU75627859<br/>
            FN: 534119w<br/>
            E-mail: <a href="mailto:office@2x.vision">office@2x.vision</a>
        </p>
        <br/>
        <h2>Vision2X Productions LLC</h2>
        <h3>Address</h3>
        <p>Ukraine, 65044 Odesa, French Boulevard, Building 33, Office 301 <br/>
            EDRPOU: 4485253<br/>
            E-mail: <a href="mailto:office@2x.vision">office@2x.vision</a>
        </p>
    </div>
};


export default Imprint;